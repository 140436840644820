import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a multipart file upload. */
  Upload: any;
};

export type RequestTransferPayload = {
  __typename?: 'RequestTransferPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** 銀行名 */
  bankName: Scalars['String'];
  /**
   * 支店名
   * @deprecated Use `branchCode`.
   */
  branchName?: Maybe<Scalars['String']>;
  /** 支店コード */
  branchCode: Scalars['String'];
  /** 口座種別 1: 普通、2: 当座 */
  accountType: Scalars['Int'];
  /** 口座名義（姓） */
  accountHolderLastName: Scalars['String'];
  /** 口座名義（名） */
  accountHolderFirstName: Scalars['String'];
  /** 口座番号 */
  accountNumber: Scalars['String'];
  /** 振込金額 */
  amount: Scalars['Int'];
};

export type CreatorAccountIntroducePayload = {
  __typename?: 'CreatorAccountIntroducePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
};

/** Query */
export type Query = {
  __typename?: 'Query';
  /**
   * 管理画面用のもの
   * 一旦はページングなどは無しで進めていくため必要なさそう
   */
  users: Array<User>;
  accounts: Array<Account>;
  currentUser?: Maybe<User>;
  /** 動画リスト */
  videos?: Maybe<VideoConnection>;
  favoriteVideoFolderVideos: FavoriteVideoFolderVideos;
  favoriteVideoFolders: Array<FavoriteVideoFolder>;
  tags: Array<Tag>;
  categories: Array<Category>;
  video: Video;
  /** クリエイターのアプリ側で必要なQuery */
  me: CreatorAccount;
  /** 案件カテゴリ */
  jobCategories: Array<JobCategory>;
  /** 案件一覧 */
  jobs: Array<Job>;
  /** 銀行口座 */
  bankAccount: BankAccount;
  /** 残高 */
  wallet: Wallet;
  /** 動画進捗 */
  submissions: Array<Submission>;
  /** 職業 */
  occupations: Array<Occupation>;
  /** 都道府県 */
  residences: Array<Residence>;
  /** 広告主側 */
  actor: Actor;
  /** モデル一覧 購入済みもののみかどうか */
  actors: Array<Actor>;
  /** モデルごとの動画 */
  actorVideos: Array<Video>;
  /** 購入済み動画 アカウントが購入済みの動画を表示する */
  buyedVideos: Array<Video>;
};


/** Query */
export type QueryVideosArgs = {
  categoryName?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
};


/** Query */
export type QueryFavoriteVideoFolderVideosArgs = {
  favoriteVideoFolderId: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


/** Query */
export type QueryVideoArgs = {
  videoId: Scalars['Int'];
};


/** Query */
export type QueryJobsArgs = {
  jobCategoryId?: Maybe<Scalars['Int']>;
};


/** Query */
export type QueryActorArgs = {
  actorId: Scalars['Int'];
};


/** Query */
export type QueryActorsArgs = {
  buyedOnly: Scalars['Boolean'];
};


/** Query */
export type QueryActorVideosArgs = {
  actorId: Scalars['Int'];
};

export type Video = {
  __typename?: 'Video';
  id: Scalars['ID'];
  convertedVideoURL: Scalars['String'];
  thumbnailImgURL: Scalars['String'];
  thumbnailVideoURL: Scalars['String'];
  displayVideoURL: Scalars['String'];
  confirmStatus: Scalars['Int'];
  subCategories: Array<SubCategory>;
  uploadedOn: Scalars['String'];
  tags: Array<Tag>;
  /** CurrentAccountが購入済みであるかどうか */
  hasBuyed: Scalars['Boolean'];
  /** Userがお気に入り登録済みであるかどうか */
  hasFavorited: Scalars['Boolean'];
  /** 購入可能な動画かどうか（モデルロック動画はモデルロック解除しないと購入できない） */
  available: Scalars['Boolean'];
  /** 全購入回数 */
  buyedCount: Scalars['Int'];
  /** 全閲覧回数 */
  viewCount: Scalars['Int'];
  /** 動画販売タイプ 1: モデルロック動画、 2: 通常版動画 */
  salesType: Scalars['Int'];
};

export type WalletHistory = {
  __typename?: 'WalletHistory';
  /** イベント日時 ISO8601 */
  eventedAt: Scalars['String'];
  /** 内容 */
  description: Scalars['String'];
  /** 金額（マイナスも含む） */
  amount: Scalars['Int'];
  /** エラー内容 */
  errorDetail?: Maybe<Scalars['String']>;
};

export type RenameFavoriteVideoFolderPayload = {
  __typename?: 'RenameFavoriteVideoFolderPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** お気に入り動画フォルダ */
  favoriteVideoFolder: FavoriteVideoFolder;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  /** 銀行名 */
  bankName: Scalars['String'];
  /**
   * 支店名
   * @deprecated Use `branchCode`.
   */
  branchName: Scalars['String'];
  /** 支店コード */
  branchCode: Scalars['String'];
  /** 口座種別 1: 普通、2: 当座 */
  accountType: Scalars['Int'];
  /** 口座名義（姓） */
  accountHolderLastName: Scalars['String'];
  /** 口座名義（名） */
  accountHolderFirstName: Scalars['String'];
  /** 口座番号 */
  accountNumber: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** お気に入り動画フォルダを作成 */
  createFavoriteVideoFolder: CreateFavoriteVideoFolderPayload;
  /** お気に入り動画フォルダ名を変更 */
  renameFavoriteVideoFolder: RenameFavoriteVideoFolderPayload;
  /** デフォルトのお気に入り動画フォルダを変更 */
  changeDefaultFavoriteVideoFolder: ChangeDefaultFavoriteVideoFolderPayload;
  /** お気に入り動画フォルダを削除 */
  removeFavoriteVideoFolder: RemoveFavoriteVideoFolderPayload;
  /** 動画をお気に入り動画フォルダに追加 */
  addFavoriteVideo: AddFavoriteVideoPayload;
  /** 動画をお気に入り動画フォルダから削除 */
  removeFavoriteVideo: RemoveFavoriteVideoPayload;
  /** 動画をお気に入りに追加 deprecated */
  changeFavoriteVideo: ChangeFavoriteVideoPayload;
  /** 動画購入 */
  buyVideo: BuyVideoPayload;
  /** クリエイタープロフィールの更新 */
  updateCreatorAccount: UpdateCreatorAccountPayload;
  /** 振込申請 */
  requestTransfer: RequestTransferPayload;
  /** 紹介者 */
  creatorAccountIntroduce: CreatorAccountIntroducePayload;
};


export type MutationCreateFavoriteVideoFolderArgs = {
  input: CreateFavoriteVideoFolderInput;
};


export type MutationRenameFavoriteVideoFolderArgs = {
  input: RenameFavoriteVideoFolderInput;
};


export type MutationChangeDefaultFavoriteVideoFolderArgs = {
  input: ChangeDefaultFavoriteVideoFolderInput;
};


export type MutationRemoveFavoriteVideoFolderArgs = {
  input: RemoveFavoriteVideoFolderInput;
};


export type MutationAddFavoriteVideoArgs = {
  input: AddFavoriteVideoInput;
};


export type MutationRemoveFavoriteVideoArgs = {
  input: RemoveFavoriteVideoInput;
};


export type MutationChangeFavoriteVideoArgs = {
  input: ChangeFavoriteVideoInput;
};


export type MutationBuyVideoArgs = {
  input: BuyVideoInput;
};


export type MutationUpdateCreatorAccountArgs = {
  input: UpdateCreatorAccountInput;
};


export type MutationRequestTransferArgs = {
  input: RequestTransferInput;
};


export type MutationCreatorAccountIntroduceArgs = {
  input: CreatorAccountIntroduceInput;
};

export type BuyVideoInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  videoId: Scalars['ID'];
};

export type Regulation = {
  __typename?: 'Regulation';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ChangeFavoriteVideoPayload = {
  __typename?: 'ChangeFavoriteVideoPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  Video: Video;
  add: Scalars['Boolean'];
};

export type RenameFavoriteVideoFolderInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** お気に入り動画フォルダID */
  favoriteVideoFolderId: Scalars['ID'];
  /** お気に入り動画フォルダ名 */
  name: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
};

/** 応募状況 */
export type Submission = {
  __typename?: 'Submission';
  id: Scalars['ID'];
  /** 動画 */
  video: Video;
  /** 案件 */
  job: Job;
  /** 報酬額 */
  fee: Scalars['Int'];
  /** ステータス 1: 審査中、2: 審査OK、3: 審査NG */
  status: Scalars['Int'];
  /** コメント内容 */
  comment: Scalars['String'];
  /** 応募日時 ISO8601 */
  uploadedAt: Scalars['String'];
};

export type CreatorAccountIntroduceInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['ID'];
  name: Scalars['String'];
  profileImageURL: Scalars['String'];
  users: Array<User>;
};

export type UpdateCreatorAccountPayload = {
  __typename?: 'UpdateCreatorAccountPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  CreatorAccount: CreatorAccount;
};

/** 有効な動画リスト */
export type VideoConnection = {
  __typename?: 'VideoConnection';
  pageInfo: PageInfo;
  edges: Array<VideoEdge>;
  nodes: Array<Video>;
  totalCount: Scalars['Int'];
};

/** 案件カテゴリ */
export type JobCategory = {
  __typename?: 'JobCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  imgUrl: Scalars['String'];
};

/** 職業 */
export type Occupation = {
  __typename?: 'Occupation';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ChangeDefaultFavoriteVideoFolderPayload = {
  __typename?: 'ChangeDefaultFavoriteVideoFolderPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** お気に入り動画フォルダ */
  favoriteVideoFolder: FavoriteVideoFolder;
};

export type CreateFavoriteVideoFolderInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** お気に入り動画フォルダ名 */
  name: Scalars['String'];
  /** フォルダに追加する動画ID */
  videoId?: Maybe<Scalars['String']>;
};

export type AddFavoriteVideoPayload = {
  __typename?: 'AddFavoriteVideoPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  videoId: Scalars['ID'];
  /** ユーザがお気に入りにしているかどうかの状態をbooleanで返す */
  viewerHasFavorited: Scalars['Boolean'];
};

export type RemoveFavoriteVideoInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  videoId: Scalars['ID'];
  favoriteVideoFolderId: Scalars['ID'];
};

export type UpdateVideoPayload = {
  __typename?: 'UpdateVideoPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  video: Video;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  username: Scalars['String'];
  displayName: Scalars['String'];
  email: Scalars['String'];
  profileImageURL: Scalars['String'];
  /** 通常版の動画の購入が可能かどうか */
  videoBuyable: Scalars['Boolean'];
  /** モデルロック版の動画の購入が可能かどうか */
  actorVideoBuyable: Scalars['Boolean'];
  /** モデルロック可能かどうか */
  actorBuyable: Scalars['Boolean'];
};

export type Wallet = {
  __typename?: 'Wallet';
  /** 現在の残高 */
  amount: Scalars['Int'];
  /** 残高の履歴 */
  histories: Array<WalletHistory>;
  /** 振込申請状態 1: 未申請、2: 申請中 */
  transferStatus: Scalars['Int'];
};

export type BuyVideoPayload = {
  __typename?: 'BuyVideoPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  video: Video;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  imgUrl: Scalars['String'];
  subCategories: Array<SubCategory>;
};

/** 居住地 */
export type Residence = {
  __typename?: 'Residence';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ChangeDefaultFavoriteVideoFolderInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** お気に入り動画フォルダID */
  favoriteVideoFolderId: Scalars['ID'];
};

export type CreateFavoriteVideoFolderPayload = {
  __typename?: 'CreateFavoriteVideoFolderPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  favoriteVideoFolder: FavoriteVideoFolder;
};

/** 振込申請 */
export type RequestTransferInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** 銀行名 */
  bankName: Scalars['String'];
  /** 支店名 */
  branchName?: Maybe<Scalars['String']>;
  /** 支店コード */
  branchCode: Scalars['String'];
  /** 口座種別 1: 普通、2: 当座 */
  accountType: Scalars['Int'];
  /** 口座名義（姓） */
  accountHolderLastName: Scalars['String'];
  /** 口座名義（名） */
  accountHolderFirstName: Scalars['String'];
  /** 口座番号 */
  accountNumber: Scalars['String'];
  /** 振込金額 */
  amount: Scalars['Int'];
};

/** モデル */
export type Actor = {
  __typename?: 'Actor';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  /** CurrentAccountが購入済みであるかどうか */
  hasBuyed: Scalars['Boolean'];
  /** 購入できるかどうか（他のアカウントからモデルロックされていたら購入不可） */
  buyable: Scalars['Boolean'];
};

export type SubCategory = {
  __typename?: 'SubCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: Category;
};

/** 案件 */
export type Job = {
  __typename?: 'Job';
  id: Scalars['ID'];
  /** タイトル */
  title: Scalars['String'];
  /** 応募概要 */
  summary: Scalars['String'];
  /** 応募条件 */
  conditions: Scalars['String'];
  /** TOPに出す画像 */
  coverImgUrl: Scalars['String'];
  /** 参考動画 */
  referenceVideoUrls: Array<Scalars['String']>;
  /** 報酬 */
  fee: Scalars['Int'];
  /** レギュレーション（動画の長さ、画面の向き、服装、場所が入る予定） */
  regulations: Array<Regulation>;
  /** 応募開始日時 ISO8601 */
  startedAt: Scalars['String'];
  /** 応募終了日時 ISO8601 */
  endedAt: Scalars['String'];
  /** 注意事項 */
  attention?: Maybe<Scalars['String']>;
};

export type ChangeFavoriteVideoInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  videoId: Scalars['ID'];
  /** true: 追加、false: 削除 */
  add: Scalars['Boolean'];
};

export type FavoriteVideoFolderVideos = {
  __typename?: 'FavoriteVideoFolderVideos';
  id: Scalars['ID'];
  folderName: Scalars['String'];
  videos?: Maybe<VideoConnection>;
};

export type RemoveFavoriteVideoFolderInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** お気に入り動画フォルダID */
  favoriteVideoFolderId: Scalars['ID'];
};

export type RemoveFavoriteVideoFolderPayload = {
  __typename?: 'RemoveFavoriteVideoFolderPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveFavoriteVideoPayload = {
  __typename?: 'RemoveFavoriteVideoPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  videoId: Scalars['ID'];
  /** ユーザがお気に入りにしているかどうかの状態をbooleanで返す */
  viewerHasFavorited: Scalars['Boolean'];
};

/** クリエイタープロフィールの更新 */
export type UpdateCreatorAccountInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** 性別 0:　不明、1: 男性、2: 女性、9: 適用不能 https://ja.wikipedia.org/wiki/ISO_5218 */
  gender?: Maybe<Scalars['Int']>;
  /** 職業 */
  occupationId?: Maybe<Scalars['Int']>;
  /** 居住地（都道府県） */
  residenceId?: Maybe<Scalars['Int']>;
  /** 生年月日 ISO8601 */
  dateOfBirth?: Maybe<Scalars['String']>;
  /** プロフィール画像 */
  profileImgFile?: Maybe<Scalars['Upload']>;
};


export type FavoriteVideoFolder = {
  __typename?: 'FavoriteVideoFolder';
  id: Scalars['ID'];
  name: Scalars['String'];
  uuid: Scalars['String'];
  /** 公開ステータス 1: 公開済、2: 非公開 */
  publishStatus: Scalars['Int'];
  /** 紐づくvideoを返す TODO: Connectionで実装する */
  videos?: Maybe<Array<Video>>;
};

export type VideoEdge = {
  __typename?: 'VideoEdge';
  node: Video;
  cursor: Scalars['String'];
};

/** Creator */
export type CreatorAccount = {
  __typename?: 'CreatorAccount';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  /** 性別 0:　不明、1: 男性、2: 女性、9: 適用不能 https://ja.wikipedia.org/wiki/ISO_5218 */
  gender?: Maybe<Scalars['Int']>;
  /** 居住地（都道府県） */
  residence?: Maybe<Residence>;
  /** 職業 */
  occupation?: Maybe<Occupation>;
  /** 生年月日 ISO8601 */
  dateOfBirth?: Maybe<Scalars['String']>;
  /** Creator ProfileURL */
  profileImgURL?: Maybe<Scalars['String']>;
};

export type AddFavoriteVideoInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  videoId: Scalars['ID'];
  favoriteVideoFolderId: Scalars['ID'];
};

export type HeaderAppBarFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'videoBuyable' | 'actorVideoBuyable' | 'profileImageURL'>
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & HeaderAppBarFragment
  )> }
);

export type GetGridListVideosQueryVariables = Exact<{
  categoryName: Scalars['String'];
  tagName?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetGridListVideosQuery = (
  { __typename?: 'Query' }
  & { videos?: Maybe<(
    { __typename?: 'VideoConnection' }
    & Pick<VideoConnection, 'totalCount'>
    & { edges: Array<(
      { __typename?: 'VideoEdge' }
      & Pick<VideoEdge, 'cursor'>
      & { node: (
        { __typename?: 'Video' }
        & Pick<Video, 'id' | 'thumbnailImgURL' | 'thumbnailVideoURL'>
        & { tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'id' | 'name'>
        )> }
      ) }
    )> }
  )> }
);

export type GetVideoListSideBarCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVideoListSideBarCategoriesQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
    & { subCategories: Array<(
      { __typename?: 'SubCategory' }
      & Pick<SubCategory, 'id' | 'name'>
    )> }
  )> }
);

export type GetSideBarCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSideBarCategoriesQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
    & { subCategories: Array<(
      { __typename?: 'SubCategory' }
      & Pick<SubCategory, 'id' | 'name'>
    )> }
  )> }
);

export type GetSideBarTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSideBarTagsQuery = (
  { __typename?: 'Query' }
  & { tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
);

export type GetActorDetailQueryVariables = Exact<{
  actorId: Scalars['Int'];
}>;


export type GetActorDetailQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'actorBuyable'>
  )>, actor: (
    { __typename?: 'Actor' }
    & Pick<Actor, 'id' | 'name' | 'url' | 'hasBuyed' | 'buyable'>
  ), actorVideos: Array<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'displayVideoURL' | 'thumbnailImgURL'>
  )> }
);

export type GetActorsQueryVariables = Exact<{
  buyedOnly: Scalars['Boolean'];
}>;


export type GetActorsQuery = (
  { __typename?: 'Query' }
  & { actors: Array<(
    { __typename?: 'Actor' }
    & Pick<Actor, 'id' | 'name' | 'url'>
  )> }
);

export type GetVideoQueryVariables = Exact<{
  videoId: Scalars['Int'];
}>;


export type GetVideoQuery = (
  { __typename?: 'Query' }
  & { video: (
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'displayVideoURL' | 'convertedVideoURL' | 'hasBuyed' | 'hasFavorited' | 'buyedCount' | 'viewCount' | 'uploadedOn'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )> }
  ), currentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'videoBuyable'>
  )> }
);

export type ChangeFavoriteVideoMutationVariables = Exact<{
  videoId: Scalars['ID'];
  add: Scalars['Boolean'];
}>;


export type ChangeFavoriteVideoMutation = (
  { __typename?: 'Mutation' }
  & { changeFavoriteVideo: (
    { __typename?: 'ChangeFavoriteVideoPayload' }
    & Pick<ChangeFavoriteVideoPayload, 'add'>
    & { Video: (
      { __typename?: 'Video' }
      & Pick<Video, 'id'>
    ) }
  ) }
);

export type BuyVideoMutationVariables = Exact<{
  videoId: Scalars['ID'];
}>;


export type BuyVideoMutation = (
  { __typename?: 'Mutation' }
  & { buyVideo: (
    { __typename?: 'BuyVideoPayload' }
    & { video: (
      { __typename?: 'Video' }
      & Pick<Video, 'id' | 'convertedVideoURL'>
    ) }
  ) }
);

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoriesQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'imgUrl'>
    & { subCategories: Array<(
      { __typename?: 'SubCategory' }
      & Pick<SubCategory, 'id' | 'name'>
    )> }
  )> }
);

export const HeaderAppBarFragmentDoc = gql`
    fragment HeaderAppBar on User {
  id
  videoBuyable
  actorVideoBuyable
  profileImageURL
}
    `;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  currentUser {
    ...HeaderAppBar
  }
}
    ${HeaderAppBarFragmentDoc}`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetGridListVideosDocument = gql`
    query getGridListVideos($categoryName: String!, $tagName: String, $page: Int) {
  videos(categoryName: $categoryName, tagName: $tagName, page: $page) {
    edges {
      cursor
      node {
        id
        thumbnailImgURL
        thumbnailVideoURL
        tags {
          id
          name
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetGridListVideosQuery__
 *
 * To run a query within a React component, call `useGetGridListVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGridListVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGridListVideosQuery({
 *   variables: {
 *      categoryName: // value for 'categoryName'
 *      tagName: // value for 'tagName'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetGridListVideosQuery(baseOptions?: Apollo.QueryHookOptions<GetGridListVideosQuery, GetGridListVideosQueryVariables>) {
        return Apollo.useQuery<GetGridListVideosQuery, GetGridListVideosQueryVariables>(GetGridListVideosDocument, baseOptions);
      }
export function useGetGridListVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGridListVideosQuery, GetGridListVideosQueryVariables>) {
          return Apollo.useLazyQuery<GetGridListVideosQuery, GetGridListVideosQueryVariables>(GetGridListVideosDocument, baseOptions);
        }
export type GetGridListVideosQueryHookResult = ReturnType<typeof useGetGridListVideosQuery>;
export type GetGridListVideosLazyQueryHookResult = ReturnType<typeof useGetGridListVideosLazyQuery>;
export type GetGridListVideosQueryResult = Apollo.QueryResult<GetGridListVideosQuery, GetGridListVideosQueryVariables>;
export const GetVideoListSideBarCategoriesDocument = gql`
    query getVideoListSideBarCategories {
  categories {
    id
    name
    subCategories {
      id
      name
    }
  }
}
    `;

/**
 * __useGetVideoListSideBarCategoriesQuery__
 *
 * To run a query within a React component, call `useGetVideoListSideBarCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoListSideBarCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoListSideBarCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVideoListSideBarCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetVideoListSideBarCategoriesQuery, GetVideoListSideBarCategoriesQueryVariables>) {
        return Apollo.useQuery<GetVideoListSideBarCategoriesQuery, GetVideoListSideBarCategoriesQueryVariables>(GetVideoListSideBarCategoriesDocument, baseOptions);
      }
export function useGetVideoListSideBarCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideoListSideBarCategoriesQuery, GetVideoListSideBarCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<GetVideoListSideBarCategoriesQuery, GetVideoListSideBarCategoriesQueryVariables>(GetVideoListSideBarCategoriesDocument, baseOptions);
        }
export type GetVideoListSideBarCategoriesQueryHookResult = ReturnType<typeof useGetVideoListSideBarCategoriesQuery>;
export type GetVideoListSideBarCategoriesLazyQueryHookResult = ReturnType<typeof useGetVideoListSideBarCategoriesLazyQuery>;
export type GetVideoListSideBarCategoriesQueryResult = Apollo.QueryResult<GetVideoListSideBarCategoriesQuery, GetVideoListSideBarCategoriesQueryVariables>;
export const GetSideBarCategoriesDocument = gql`
    query getSideBarCategories {
  categories {
    id
    name
    subCategories {
      id
      name
    }
  }
}
    `;

/**
 * __useGetSideBarCategoriesQuery__
 *
 * To run a query within a React component, call `useGetSideBarCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSideBarCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSideBarCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSideBarCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetSideBarCategoriesQuery, GetSideBarCategoriesQueryVariables>) {
        return Apollo.useQuery<GetSideBarCategoriesQuery, GetSideBarCategoriesQueryVariables>(GetSideBarCategoriesDocument, baseOptions);
      }
export function useGetSideBarCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSideBarCategoriesQuery, GetSideBarCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<GetSideBarCategoriesQuery, GetSideBarCategoriesQueryVariables>(GetSideBarCategoriesDocument, baseOptions);
        }
export type GetSideBarCategoriesQueryHookResult = ReturnType<typeof useGetSideBarCategoriesQuery>;
export type GetSideBarCategoriesLazyQueryHookResult = ReturnType<typeof useGetSideBarCategoriesLazyQuery>;
export type GetSideBarCategoriesQueryResult = Apollo.QueryResult<GetSideBarCategoriesQuery, GetSideBarCategoriesQueryVariables>;
export const GetSideBarTagsDocument = gql`
    query getSideBarTags {
  tags {
    id
    name
  }
}
    `;

/**
 * __useGetSideBarTagsQuery__
 *
 * To run a query within a React component, call `useGetSideBarTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSideBarTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSideBarTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSideBarTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetSideBarTagsQuery, GetSideBarTagsQueryVariables>) {
        return Apollo.useQuery<GetSideBarTagsQuery, GetSideBarTagsQueryVariables>(GetSideBarTagsDocument, baseOptions);
      }
export function useGetSideBarTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSideBarTagsQuery, GetSideBarTagsQueryVariables>) {
          return Apollo.useLazyQuery<GetSideBarTagsQuery, GetSideBarTagsQueryVariables>(GetSideBarTagsDocument, baseOptions);
        }
export type GetSideBarTagsQueryHookResult = ReturnType<typeof useGetSideBarTagsQuery>;
export type GetSideBarTagsLazyQueryHookResult = ReturnType<typeof useGetSideBarTagsLazyQuery>;
export type GetSideBarTagsQueryResult = Apollo.QueryResult<GetSideBarTagsQuery, GetSideBarTagsQueryVariables>;
export const GetActorDetailDocument = gql`
    query getActorDetail($actorId: Int!) {
  currentUser {
    id
    actorBuyable
  }
  actor(actorId: $actorId) {
    id
    name
    url
    hasBuyed
    buyable
  }
  actorVideos(actorId: $actorId) {
    id
    displayVideoURL
    thumbnailImgURL
  }
}
    `;

/**
 * __useGetActorDetailQuery__
 *
 * To run a query within a React component, call `useGetActorDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActorDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActorDetailQuery({
 *   variables: {
 *      actorId: // value for 'actorId'
 *   },
 * });
 */
export function useGetActorDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetActorDetailQuery, GetActorDetailQueryVariables>) {
        return Apollo.useQuery<GetActorDetailQuery, GetActorDetailQueryVariables>(GetActorDetailDocument, baseOptions);
      }
export function useGetActorDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActorDetailQuery, GetActorDetailQueryVariables>) {
          return Apollo.useLazyQuery<GetActorDetailQuery, GetActorDetailQueryVariables>(GetActorDetailDocument, baseOptions);
        }
export type GetActorDetailQueryHookResult = ReturnType<typeof useGetActorDetailQuery>;
export type GetActorDetailLazyQueryHookResult = ReturnType<typeof useGetActorDetailLazyQuery>;
export type GetActorDetailQueryResult = Apollo.QueryResult<GetActorDetailQuery, GetActorDetailQueryVariables>;
export const GetActorsDocument = gql`
    query getActors($buyedOnly: Boolean!) {
  actors(buyedOnly: $buyedOnly) {
    id
    name
    url
  }
}
    `;

/**
 * __useGetActorsQuery__
 *
 * To run a query within a React component, call `useGetActorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActorsQuery({
 *   variables: {
 *      buyedOnly: // value for 'buyedOnly'
 *   },
 * });
 */
export function useGetActorsQuery(baseOptions?: Apollo.QueryHookOptions<GetActorsQuery, GetActorsQueryVariables>) {
        return Apollo.useQuery<GetActorsQuery, GetActorsQueryVariables>(GetActorsDocument, baseOptions);
      }
export function useGetActorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActorsQuery, GetActorsQueryVariables>) {
          return Apollo.useLazyQuery<GetActorsQuery, GetActorsQueryVariables>(GetActorsDocument, baseOptions);
        }
export type GetActorsQueryHookResult = ReturnType<typeof useGetActorsQuery>;
export type GetActorsLazyQueryHookResult = ReturnType<typeof useGetActorsLazyQuery>;
export type GetActorsQueryResult = Apollo.QueryResult<GetActorsQuery, GetActorsQueryVariables>;
export const GetVideoDocument = gql`
    query getVideo($videoId: Int!) {
  video(videoId: $videoId) {
    id
    displayVideoURL
    convertedVideoURL
    tags {
      id
      name
    }
    hasBuyed
    hasFavorited
    buyedCount
    viewCount
    uploadedOn
  }
  currentUser {
    id
    videoBuyable
  }
}
    `;

/**
 * __useGetVideoQuery__
 *
 * To run a query within a React component, call `useGetVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useGetVideoQuery(baseOptions?: Apollo.QueryHookOptions<GetVideoQuery, GetVideoQueryVariables>) {
        return Apollo.useQuery<GetVideoQuery, GetVideoQueryVariables>(GetVideoDocument, baseOptions);
      }
export function useGetVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideoQuery, GetVideoQueryVariables>) {
          return Apollo.useLazyQuery<GetVideoQuery, GetVideoQueryVariables>(GetVideoDocument, baseOptions);
        }
export type GetVideoQueryHookResult = ReturnType<typeof useGetVideoQuery>;
export type GetVideoLazyQueryHookResult = ReturnType<typeof useGetVideoLazyQuery>;
export type GetVideoQueryResult = Apollo.QueryResult<GetVideoQuery, GetVideoQueryVariables>;
export const ChangeFavoriteVideoDocument = gql`
    mutation ChangeFavoriteVideo($videoId: ID!, $add: Boolean!) {
  changeFavoriteVideo(input: {videoId: $videoId, add: $add}) {
    Video {
      id
    }
    add
  }
}
    `;
export type ChangeFavoriteVideoMutationFn = Apollo.MutationFunction<ChangeFavoriteVideoMutation, ChangeFavoriteVideoMutationVariables>;

/**
 * __useChangeFavoriteVideoMutation__
 *
 * To run a mutation, you first call `useChangeFavoriteVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeFavoriteVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeFavoriteVideoMutation, { data, loading, error }] = useChangeFavoriteVideoMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      add: // value for 'add'
 *   },
 * });
 */
export function useChangeFavoriteVideoMutation(baseOptions?: Apollo.MutationHookOptions<ChangeFavoriteVideoMutation, ChangeFavoriteVideoMutationVariables>) {
        return Apollo.useMutation<ChangeFavoriteVideoMutation, ChangeFavoriteVideoMutationVariables>(ChangeFavoriteVideoDocument, baseOptions);
      }
export type ChangeFavoriteVideoMutationHookResult = ReturnType<typeof useChangeFavoriteVideoMutation>;
export type ChangeFavoriteVideoMutationResult = Apollo.MutationResult<ChangeFavoriteVideoMutation>;
export type ChangeFavoriteVideoMutationOptions = Apollo.BaseMutationOptions<ChangeFavoriteVideoMutation, ChangeFavoriteVideoMutationVariables>;
export const BuyVideoDocument = gql`
    mutation BuyVideo($videoId: ID!) {
  buyVideo(input: {videoId: $videoId}) {
    video {
      id
      convertedVideoURL
    }
  }
}
    `;
export type BuyVideoMutationFn = Apollo.MutationFunction<BuyVideoMutation, BuyVideoMutationVariables>;

/**
 * __useBuyVideoMutation__
 *
 * To run a mutation, you first call `useBuyVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyVideoMutation, { data, loading, error }] = useBuyVideoMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useBuyVideoMutation(baseOptions?: Apollo.MutationHookOptions<BuyVideoMutation, BuyVideoMutationVariables>) {
        return Apollo.useMutation<BuyVideoMutation, BuyVideoMutationVariables>(BuyVideoDocument, baseOptions);
      }
export type BuyVideoMutationHookResult = ReturnType<typeof useBuyVideoMutation>;
export type BuyVideoMutationResult = Apollo.MutationResult<BuyVideoMutation>;
export type BuyVideoMutationOptions = Apollo.BaseMutationOptions<BuyVideoMutation, BuyVideoMutationVariables>;
export const GetCategoriesDocument = gql`
    query getCategories {
  categories {
    id
    name
    imgUrl
    subCategories {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, baseOptions);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, baseOptions);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;